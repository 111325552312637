
import { defineComponent } from 'vue'
import Highlight from '@/components/shared/Highlight.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'

export default defineComponent({
  components: { TmTooltip, Highlight },
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    attachments: {
      type: Number,
    },
    chevron: {
      type: Boolean,
    },
    tooltipMaxWidth: {
      type: String,
      default: '270px',
    },
    search: {
      type: String,
      default: '',
    },
  },
})
