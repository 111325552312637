import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56545b5d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-carbon-copy" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createBlock(_component_tm_avatar, {
        key: i,
        size: "medium",
        outlined: "",
        tooltip: _ctx.showTooltip ? item.fullName : '',
        name: item.fullName,
        color: item.avatarColor,
        url: item.avatar,
        class: "pointer",
        onClick: ($event: any) => (_ctx.$emit('item-click', item))
      }, null, 8, ["tooltip", "name", "color", "url", "onClick"]))
    }), 128))
  ]))
}