import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_priority = _resolveComponent("tm-priority")!
  const _component_filter_checkbox = _resolveComponent("filter-checkbox")!

  return (_openBlock(), _createBlock(_component_filter_checkbox, {
    "option-key": "name",
    "option-label": "name",
    "search-placeholder": "Search",
    searchable: ""
  }, {
    option: _withCtx(({ option }) => [
      _createVNode(_component_tm_priority, {
        type: option,
        "semi-bold": ""
      }, null, 8, ["type"])
    ]),
    _: 1
  }))
}