import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79335686"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "attachments"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_highlight = _resolveComponent("highlight")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dropdown-list-item", {
      'dropdown-list-item--small': !_ctx.text,
      'dropdown-list-item--with-attachments': _ctx.attachments
    }])
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_tm_icon, {
          key: 0,
          name: _ctx.icon,
          size: "xLarge"
        }, null, 8, ["name"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createElementVNode("h6", null, [
        _createVNode(_component_highlight, {
          text: _ctx.title,
          search: _ctx.search
        }, null, 8, ["text", "search"])
      ]),
      (_ctx.text)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
            _createVNode(_component_highlight, {
              text: _ctx.text,
              search: _ctx.search
            }, null, 8, ["text", "search"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.attachments)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.attachments) + " ", 1),
          _createVNode(_component_tm_icon, {
            name: "tmi-attachment",
            "custom-size": "16px"
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.chevron)
      ? (_openBlock(), _createBlock(_component_tm_icon, {
          key: 2,
          name: "chevron_right",
          size: "xLarge",
          class: "chevron"
        }))
      : _createCommentVNode("", true),
    (_ctx.$slots['tooltip'])
      ? (_openBlock(), _createBlock(_component_tm_tooltip, {
          key: 3,
          class: "pa-10px",
          position: "right",
          white: "",
          "max-width": _ctx.tooltipMaxWidth
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "tooltip", {}, undefined, true)
          ]),
          _: 3
        }, 8, ["max-width"]))
      : _createCommentVNode("", true)
  ], 2))
}