import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_filter_button = _resolveComponent("top-filter-button")!
  const _component_dropdown_filter_priority = _resolveComponent("dropdown-filter-priority")!
  const _component_dropdown_filter_assignee = _resolveComponent("dropdown-filter-assignee")!
  const _component_dropdown_filter_radio = _resolveComponent("dropdown-filter-radio")!
  const _component_dropdown_filter_statuses = _resolveComponent("dropdown-filter-statuses")!
  const _component_filter_add = _resolveComponent("filter-add")!
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, {
    "search-placeholder": "Search tickets",
    selected: _ctx.selected,
    "show-filters": "",
    "hide-columns-additional-buttons": "",
    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('update:model-value', $event))),
    "onUpdate:radioValue": _cache[14] || (_cache[14] = ($event: any) => (_ctx.$emit('update:radio-value', $event))),
    onReset: _cache[15] || (_cache[15] = ($event: any) => (_ctx.$emit('reset')))
  }, {
    "filter-line-left-default": _withCtx(() => [
      (!_ctx.modalActions)
        ? (_openBlock(), _createBlock(_component_top_filter_button, {
            key: 0,
            text: "New ticket",
            icon: "add",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal('tickets')))
          }))
        : (_openBlock(), _createBlock(_component_top_filter_button, {
            key: 1,
            text: "Download",
            icon: "get_app"
          }))
    ]),
    "filter-line-left-selected": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "Mark as solved",
        icon: "tmi-checkbox-alt-circle",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openSolveTickets(_ctx.selected)))
      }),
      _createVNode(_component_top_filter_button, {
        text: "Edit",
        icon: "edit",
        onClick: _ctx.clickEdit
      }, null, 8, ["onClick"]),
      _createVNode(_component_top_filter_button, {
        icon: "account_circle",
        text: "Assign",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openAssigneeBulkModal(_ctx.selected)))
      }),
      _createVNode(_component_top_filter_button, {
        icon: "tmi-followers-add",
        text: "Follow",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.followMessage(_ctx.selected.length === 1)))
      }),
      _createVNode(_component_top_filter_button, {
        icon: "call_merge",
        text: "Merge into...",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openMultipleMerge(_ctx.selected)))
      }),
      (!_ctx.modalActions)
        ? (_openBlock(), _createBlock(_component_top_filter_button, {
            key: 0,
            text: "Download",
            icon: "get_app"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_top_filter_button, {
        text: "Mark as spam",
        icon: "report",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openMarkAsSpamModal(_ctx.selected)))
      }),
      _createVNode(_component_top_filter_button, {
        text: "Delete",
        icon: "delete",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openDeleteTicketsModal(_ctx.selected)))
      })
    ]),
    "filter-line-details": _withCtx(() => [
      _createVNode(_component_dropdown_filter_priority, {
        modelValue: _ctx.filters.priority.value,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.filters.priority.value) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_dropdown_filter_assignee, {
        modelValue: _ctx.filters.assignee.value,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.filters.assignee.value) = $event)),
        options: _ctx.filters.assignee.options,
        label: "Requester",
        "search-placeholder": "Search requesters"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_dropdown_filter_radio, _mergeProps({
        modelValue: _ctx.filters.dateCreated.value,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.filters.dateCreated.value) = $event))
      }, _ctx.filters.dateCreated, { name: "Created" }), null, 16, ["modelValue"]),
      _createVNode(_component_dropdown_filter_statuses, {
        modelValue: _ctx.filters.ticketStatusesFilter.value,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.filters.ticketStatusesFilter.value) = $event)),
        options: _ctx.filters.ticketStatusesFilter.options,
        name: "Status",
        "search-placeholder": "Search status",
        removable: false
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_dropdown_filter_radio, _mergeProps({
        modelValue: _ctx.filters.lastUpdated.value,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.filters.lastUpdated.value) = $event))
      }, _ctx.filters.lastUpdated, { name: "Last updated" }), null, 16, ["modelValue"]),
      _createVNode(_component_dropdown_filter_assignee, {
        modelValue: _ctx.filters.assignee.value,
        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.filters.assignee.value) = $event)),
        options: _ctx.filters.assignee.options,
        label: "Assignee",
        "search-placeholder": "Search assignee"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_filter_add, {
        class: "ml-2",
        size: "medium",
        "hide-icon": "",
        items: _ctx.filters.addFilters
      }, null, 8, ["items"])
    ]),
    _: 1
  }, 8, ["selected"]))
}