
import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import { useTicketsOpenModals } from '@/compositions/tickets/useTicketsOpenModal'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmDropdown,
    TmTableActionButton,
    TmDropdownItem,
  },
  props: {
    name: {
      type: String,
    },
    ticketId: {
      type: String,
    },
  },
  setup() {
    const { openDeleteTicketModal, openAssigneeModal, openMarkAsSpamModal, markReadMessage, followMessage } = useTicketsOpenModals()
    const { openModal } = useModals()

    return {
      openModal,
      openDeleteTicketModal,
      openMarkAsSpamModal,
      openAssigneeModal,
      followMessage,
      markReadMessage,
    }
  },
})
