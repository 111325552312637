
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  name: 'ViewSaver',
  components: {
    TmButton,
  },
  props: {
    hasUnsavedChanges: {
      type: Boolean,
    },
    saveAsLabel: {
      type: String,
      default: 'Save as...',
    },
    darkText: {
      type: Boolean,
    },
  },
  emits: ['reset', 'save-click'],
})
