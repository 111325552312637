import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_table_action_button = _resolveComponent("tm-table-action-button")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, null, {
    menu: _withCtx(() => [
      _createVNode(_component_tm_dropdown_item, {
        icon: "visibility",
        label: "View details",
        to: { name: 'base.tickets.details', params: { id: _ctx.ticketId } }
      }, null, 8, ["to"]),
      _createVNode(_component_tm_dropdown_item, {
        icon: "drafts",
        label: "Mark as read",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.markReadMessage(true)))
      }),
      _createVNode(_component_tm_dropdown_item, {
        icon: "account_circle",
        label: "Assign",
        onClick: _ctx.openAssigneeModal
      }, null, 8, ["onClick"]),
      _createVNode(_component_tm_dropdown_item, {
        icon: "tmi-followers-add",
        label: "Follow",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.followMessage(true)))
      }),
      _createVNode(_component_tm_dropdown_item, {
        icon: "call_merge",
        label: "Merge into...",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openModal('ticketsMerge')))
      }),
      _createVNode(_component_tm_dropdown_item, {
        icon: "report",
        label: "Mark as spam",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openMarkAsSpamModal(_ctx.name)))
      }),
      _createVNode(_component_tm_dropdown_item, {
        icon: "delete",
        label: "Delete",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openDeleteTicketModal(_ctx.name)))
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tm_table_action_button)
    ]),
    _: 1
  }))
}