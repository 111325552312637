
import { defineComponent } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import type { PropType } from 'vue'

type ItemType = {
  fullName: string;
  avatarColor: string;
  avatar?: string;
  [key: string]: any;
}

export default defineComponent({
  components: {
    TmAvatar,
  },
  props: {
    items: {
      type: Array as PropType<ItemType[]>,
      required: true,
    },
    showTooltip: {
      type: Boolean,
    },
  },
  emits: ['item-click'],
})
