
import { defineComponent, ref, computed } from 'vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import CannedResponseTemplate from '@/components/shared/editor/editorDropdowns/CannedResponseTemplate.vue'
import TmSearch from '@/components/shared/TmSearch.vue'
import DropdownListItem from '@/components/shared/editor/editorDropdowns/DropdownListItem.vue'
import { cannedResponsesDropdownItems } from '@/definitions/shared/editor/data'
import { checkMatch } from '@/services/string'
import SearchNoResults from '@/components/shared/search/SearchNoResults.vue'

export default defineComponent({
  components: {
    SearchNoResults,
    TmScrollbar,
    TmDropdown,
    CannedResponseTemplate,
    TmSearch,
    DropdownListItem,
  },
  emits: ['response-click'],
  setup(props, context) {
    const search = ref()
    const responsesList = computed(() => {
      if (!search.value) { return cannedResponsesDropdownItems }
      return cannedResponsesDropdownItems.filter((e: any) => checkMatch(e.title, search.value) || checkMatch(e.text, search.value))
    })

    const onResponseClick = (close: (...args: any[]) => any) => {
      context.emit('response-click')
      close()
    }

    return {
      responsesList,
      search,
      onResponseClick,
    }
  },
})
