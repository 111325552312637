
import { defineComponent, ref, watch, computed } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TicketsFilter from '@/components/pages/tickets/TicketsFilter.vue'
import TicketsTable from '@/components/pages/tickets/TicketsTable.vue'
import TicketsEmpty from '@/components/pages/tickets/TicketsEmpty.vue'
import ViewSaver from '@/components/shared/viewSaver/ViewSaver.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { getTableData } from '@/services/tableService'
import { useModes } from '@/compositions/modes'
import { useRoute, useRouter } from 'vue-router'
import { useFilters } from '@/compositions/filters'
import { ticketsFilter } from '@/definitions/shared/filters/filtersList/data'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TicketsTableEmpty from '@/components/pages/tickets/TicketsTableEmpty.vue'
import { checkMatch } from '@/services/string'
import { useModals } from '@/compositions/modals'
import type { GroupingItemType } from '@/definitions/_general/tables/topFilter'
import { newTicketItem } from '@/definitions/tickets/data'

export default defineComponent({
  components: {
    TmDropdownItem,
    TmButton,
    TicketsTableEmpty,
    TmDropdown,
    TicketsEmpty,
    ViewSaver,
    TicketsTable,
    TicketsFilter,
    PageContent,
  },
  props: {
    ticketsItemsStartAt: {
      type: Number,
      default: 0,
    },
    ticketsItemsEndAt: {
      type: Number,
      default: 100,
    },
    draftState: {
      type: Boolean,
    },
    title: {
      type: String,
    },
  },
  setup(props) {
    const { isEmptyMode, isEmptyHalfMode } = useModes()
    const { openModal } = useModals()
    const search = ref('')
    const selected = ref([])

    const router = useRouter()
    const route = useRoute()
    const computedTitle = computed(() => route.params.title || props.title)
    const isSimulateTicket = computed(() => route.params.simulateTicket === 'true')

    const tableHeaders = ref<TableHeaders[]>([
      { text: '', value: 'also-here', width: 30, hideSortable: true },
      { text: 'P', value: 'priority', width: 30 },
      { text: 'Subject', value: 'subject', minWidth: '40%' },
      { text: 'Requester', value: 'requester' },
      { text: 'Created', value: 'date-created', width: 140 },
      { text: 'Assignee', value: 'assignee' },
      { text: 'Status', value: 'status', width: 80 },
      { text: 'Last updated', value: 'updated', width: 140 },
      { text: 'Satisfaction', value: 'satisfaction', hidden: true },
      { text: 'Organization', value: 'organization-slot', hidden: true },
      { text: 'Inbox', value: 'inbox', hidden: true },
      { text: 'CC', value: 'cc', hidden: true },
      { text: 'Date closed', value: 'closed', hidden: true },
      { text: 'Custom field 1', value: 'custom-field', hidden: true },
    ])
    const tableItems = ref(getTableData('tickets'))
    const computedTableItems = computed(() => {
      const items = tableItems.value.slice(route.params.ticketsItemsStartAt || props.ticketsItemsStartAt, route.params.ticketsItemsEndAt || props.ticketsItemsEndAt)

      if (props.draftState) {
        return items.map((e: any) => ({
          ...e,
          statusTicket: {
            name: 'Draft',
            color: 'gray',
            outline: true,
          },
          isDraft: true,
        }))
      }

      if (isSimulateTicket.value) {
        return [newTicketItem]
      }

      return items.filter((e: any) => checkMatch(e.subject, search.value))
    })

    watch(() => isEmptyHalfMode.value, (val) => {
      if (val) {
        router.push({ name: 'base.tickets.main.gettingStarted' })
      } else {
        router.push({ name: 'base.tickets.main.emptyRoute' })
      }
    })

    const { filters, isDefault, reset } = useFilters(ticketsFilter)
    filters.value.priority.value = ['100']

    const groupingOptions: GroupingItemType[] = [
      {
        label: 'Priority',
      },
      {
        label: 'Status',
        function: (e: any) => {
          return e.statusTicket.name
        },
      },
      {
        label: 'Assignee',
        function: (e: any) => {
          return e.assignee.fullName || 'Unassigned'
        },
      },
      {
        label: 'Channel',
      },
      {
        label: 'None',
      },
    ]
    const grouping = ref(groupingOptions[1])

    return {
      openModal,
      filters,
      isDefault,
      reset,
      grouping,
      groupingOptions,
      tableItems,
      search,
      isEmptyMode,
      tableHeaders,
      computedTableItems,
      selected,
      route,
      computedTitle,
      isSimulateTicket,
    }
  },
})
