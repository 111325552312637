import { useModals } from '../modals'
import { notify } from '@kyvg/vue3-notification'
const { openModal } = useModals()

type UseTicketsOpenModals = {
  openDeleteInboxModal: () => void;
  openAssigneeModal: () => void;
  openAssigneeBulkModal: (selected: any[]) => void;
  openAddEmailLabelModal: (label: string) => void;
  openEditEmailLabelModal: (value: string, label: string) => void;
  openSendersModal: () => void;
  openDeleteTicketModal: (name: string) => void;
  openDeleteTicketsModal: (selected: any[]) => void;
  openMarkAsSpamModal: (entity: string | any[]) => void;
  openDeleteCustomFieldModal: (customFieldName: string) => void;
  openDeleteCustomFieldsModal: (selected: any[]) => void;
  openDeleteNoteModal: () => void;
  followMessage: (single: boolean) => void;
  markUnreadMessage: (single: boolean) => void;
  markReadMessage: (single: boolean) => void;
  openMultipleMerge: (selected: any[]) => void;
  openSolveTickets: (selected: any[]) => void;
  openDeleteFilteredView: () => void;
  openDeleteFilteredViews: (selected: any[]) => void;
  openNewFilteredView: () => void;
  openEditCannedResponses: () => void;
  openDuplicateCannedResponses: () => void;
  openDeleteCannedResponse: () => void;
  openDeleteCannedResponses: (selected: any[]) => void;
}

export const useTicketsOpenModals = (): UseTicketsOpenModals => {
  const openSolveTickets = (selected: any[]) => {
    openModal('confirmation', {
      title: 'Mark as solved',
      btnText: 'Mark as solved',
      text: [
        'You are going to mark ',
        {
          text: `${selected.length} tickets`,
          style: 'semi-bold',
        },
        ' as solved, which will then move them to the Solved folder.\n\nYou can Reopen, or make other action with them later, if you will need it. Are you sure you would like to continue?',
      ],
    })
  }

  const followMessage = (single: boolean) => {
    notify({ text: `You are now following ${single ? 'this ticket' : 'these tickets'} updates.`, type: 'success' })
  }
  const markUnreadMessage = (single: boolean) => {
    notify({ text: `${single ? 'This ticket' : 'These tickets'} successfully marked as unread`, type: 'success' })
  }
  const markReadMessage = (single: boolean) => {
    notify({ text: `${single ? 'This ticket' : 'These tickets'} successfully marked as read`, type: 'success' })
  }
  const openDeleteInboxModal = () => {
    openModal('confirmation', {
      title: 'Delete inbox',
      text: [
        'By deleting this inbox, you will not be able to receive any emails sent to this email address.\n\n',
        'Are you sure you want to delete it?',
      ],
      btnText: 'Delete',
      btnColor: 'error',
    })
  }

  const openAssigneeModal = () => {
    openModal('singleAssignee', {
      label: 'Assignee',
      text: 'Select an user to whom you would like to assign the ticket.',
      btnText: 'Assign',
      modalTitle: 'Assign ticket',
    })
  }
  const openAssigneeBulkModal = (selected: any[]) => {
    if (selected.length > 1) {
      openModal('singleAssignee', {
        label: 'Assignee',
        text: [
          'Select an user to whom you would like to assign these ',
          {
            text: '2 tickets.',
            style: 'semi-bold',
          },
        ],
        btnText: 'Assign',
        modalTitle: 'Assign tickets',
      })
    } else {
      openAssigneeModal()
    }
  }
  const openDeleteNoteModal = () => {
    openModal('confirmation', {
      title: 'Delete note',
      text: [
        'You are about to delete ',
        {
          text: 'John Doe',
          style: 'semi-bold',
        },
        '\'s internal note. This is internal note, so this will note affect public conversation, but it will delete this note permanently. Are you sure you want to delete this internal note?',
      ],
      btnText: 'Delete',
      btnColor: 'error',
    })
  }

  const openAddEmailLabelModal = (label: string) => {
    openModal('servicesByocLabel', {
      modalTitle: 'Add inbox label',
      itemLabel: 'Email',
      itemIcon: 'email',
      label: label,
      valueLabel: 'Inbox label',
      btnText: 'Add',
    })
  }

  const openEditEmailLabelModal = (value: string, label: string) => {
    openModal('servicesByocLabel', {
      modalTitle: 'Edit inbox label',
      itemLabel: 'Email',
      itemIcon: 'email',
      label: label,
      valueLabel: 'Inbox label',
      value: value,
      btnText: 'Save',
    })
  }

  const openSendersModal = () => {
    openModal('ticketSettingsSender')
  }
  const openDeleteTicketModal = (name: string) => {
    openModal('confirmation', {
      title: 'Delete ticket',
      text: [
        'You are going to delete the ticket ',
        {
          text: name,
          style: 'semi-bold',
        },
        ' from ',
        {
          text: 'Ralph Edwards.',
          style: 'semi-bold',
        },
        ' This action cannot be undone. Are you sure you would like to continue?',
      ],
      btnText: 'Delete',
      btnColor: 'error',
    })
  }

  const openDeleteTicketsModal = (selected: any[]) => {
    openModal('confirmation', {
      title: 'Delete tickets',
      text: [
        'You are going to delete ',
        {
          text: `${selected.length} tickets.`,
          style: 'semi-bold',
        },
        'This action cannot be undone. Are you sure you would like to continue?',
      ],
      btnText: `Delete ${selected.length} tickets`,
      btnColor: 'error',
    })
  }

  const openMarkAsSpamModal = (entity: string | any[]) => {
    let text

    if (typeof entity === 'string') {
      text = [
        'You are going to mark the ticket ',
        {
          text: entity,
          style: 'semi-bold',
        },
        ' from ',
        {
          text: 'Ralph Edwards',
          style: 'semi-bold',
        },
        ' as spam, which will then move it to the Spam folder.\n\n',
        'All future emails from the address ',
        {
          text: 'ralph@company.com',
          style: 'semi-bold',
        },
        ' will always end up in the Spam folder. Are you sure you would like to continue?',
      ]
    } else {
      text = [
        'You are going to mark ',
        {
          text: `${entity.length} tickets`,
          style: 'semi-bold',
        },
        ' as spam, which will then move them to the Spam folder.\n\n All future emails from these email addresses will always end up in the Spam folder. Are you sure you would like to continue?',
      ]
    }

    openModal('confirmation', {
      title: 'Mark as spam',
      text: text,
      btnText: 'Mark as spam',
      btnColor: 'error',
    })
  }

  const openDeleteCustomFieldModal = (customFieldName: string) => {
    openModal('confirmation', {
      title: 'Delete custom field',
      text: [
        'By confirming this action, the ticket custom field ',
        { text: customFieldName, style: 'semi-bold' },
        ' will be deleted. Note: Any data ever populated in this field will be permanently deleted.',
        '\n\nAre you sure you want to delete this field?',
      ],
      btnText: 'Delete',
      btnColor: 'red',
    })
  }

  const openDeleteCustomFieldsModal = (selected: any[]) => {
    if (selected.length > 1) {
      openModal('confirmation', {
        title: 'Delete custom fields',
        text: [
          { text: selected.length + ' ticket custom fields', style: 'semi-bold' },
          ' will be deleted. The action cannot be undone. Note: Any data ever populated in this field will be permanently deleted.',
          '\n\nAre you sure you want to delete this field?',
        ],
        btnText: 'Delete ' + selected.length + ' fields',
        btnColor: 'red',
      })
    } else {
      openDeleteCustomFieldModal(selected[0].name)
    }
  }
  const openMultipleMerge = (selected: any[]) => {
    const lengthText = selected.length > 1 ? selected.length : ''
    openModal('ticketsMerge', {
      multipleMerge: true,
      modalTitle: `Merge ${lengthText} tickets`,
      btnText: `Merge ${lengthText} tickets`,
      label: `Merge ${lengthText} tickets into`,
    })
  }

  const openNewFilteredView = () => {
    openModal('saveView', {
      modalTitle: 'Create new filtered view',
      btnText: 'Create',
      showEmoji: true,
    })
  }

  const openDeleteFilteredView = () => {
    openModal('confirmation', {
      title: 'Delete filtered view',
      btnText: 'Delete',
      btnColor: 'error',
      text: [
        'You are going to delete the filtered view ',
        {
          text: 'Open email requests',
          style: 'semi-bold',
        },
        '. This action cannot be undone. Are you sure you would like to continue?',
      ],
    })
  }
  const openDeleteFilteredViews = (selected: any[]) => {
    openModal('confirmation', {
      title: `Delete ${selected.length} filtered view${selected.length > 1 ? 's' : ''}`,
      btnText: 'Delete',
      btnColor: 'error',
      text: [
        'You are going to delete ',
        {
          text: `${selected.length} filtered view${selected.length > 1 ? 's' : ''}`,
          style: 'semi-bold',
        },
        '. This action cannot be undone. Are you sure you would like to continue?',
      ],
    })
  }

  const openEditCannedResponses = () => {
    openModal('ticketsCannedResponses', {
      modalTitle: 'Edit canned response',
      btnText: 'Save changes',
      initTitle: 'refund',
      initResponse: 'Hello {{contact.firstname}}, \n\nThank you for contacting us about your refund request. We understand your concerns and are currently processing your request. We aim to resolve this as quickly as possible and will update you within 24-48 hours.',
    })
  }

  const openDuplicateCannedResponses = () => {
    openModal('ticketsCannedResponses', {
      modalTitle: 'Duplicate canned response',
      btnText: 'Duplicate',
      initTitle: 'refund (1)',
      initResponse: 'Hello {{contact.firstname}}, \n\nThank you for contacting us about your refund request. We understand your concerns and are currently processing your request. We aim to resolve this as quickly as possible and will update you within 24-48 hours.',
    })
  }

  const openDeleteCannedResponse = () => {
    openModal('confirmation', {
      title: 'Delete canned response',
      text: [
        'You are going to delete the canned response ',
        {
          text: '#refund.',
          style: 'semi-bold',
        },
        ' This action cannot be undone. Are you sure you would like to continue?',
      ],
      btnText: 'delete',
      btnColor: 'error',
    })
  }

  const openDeleteCannedResponses = (selected: any[]) => {
    openModal('confirmation', {
      title: `Delete ${selected.length} canned response${selected.length > 1 ? 's' : ''}`,
      text: [
        'You are going to delete ',
        {
          text: `${selected.length} canned response${selected.length > 1 ? 's' : ''}.`,
          style: 'semi-bold',
        },
        ' This action cannot be undone. Are you sure you would like to continue?',
      ],
      btnText: 'Delete',
      btnColor: 'error',
    })
  }

  return {
    openNewFilteredView,
    openDeleteFilteredView,
    openDeleteFilteredViews,
    openMultipleMerge,
    openMarkAsSpamModal,
    openDeleteTicketModal,
    openDeleteNoteModal,
    openDeleteTicketsModal,
    openDeleteInboxModal,
    openAssigneeModal,
    openAssigneeBulkModal,
    openAddEmailLabelModal,
    openEditEmailLabelModal,
    openSendersModal,
    openDeleteCustomFieldModal,
    openDeleteCustomFieldsModal,
    followMessage,
    markUnreadMessage,
    markReadMessage,
    openSolveTickets,
    openEditCannedResponses,
    openDuplicateCannedResponses,
    openDeleteCannedResponse,
    openDeleteCannedResponses,
  }
}
