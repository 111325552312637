
import type { VNode } from 'vue'
import { defineComponent, h } from 'vue'

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  render(this: {text: string}): VNode {
    const regex = /@['"a-zA-Z0-9:\s]+/g
    const links = this.text.match(regex)
    const otherText = this.text.split(/@['"a-zA-Z0-9:\s]+/).filter((text) => text)
    const startsWithLink = this.text.startsWith('@')
    const content: any[] = []

    if (!links) {
      return h(
        'div',
        {
          class: 'tm-message',
          innerHTML: otherText,
        }
      )
    }

    const insertLink = (link: string) => {
      if (!link) {
        return
      }

      const linkContent = link.substring(1, link.length - 1)
      content.push(h(
        'a',
        { href: '#' },
        ['@' + linkContent]
      ))
    }

    const insertText = (text: string) => {
      if (!text) {
        return
      }

      content.push(h('span', { innerHTML: text }))
    }

    for (let i = 0; i < otherText.length; i++) {
      if (startsWithLink) {
        insertLink(links[i])
        insertText(otherText[i])
      } else {
        insertText(otherText[i])
        insertLink(links[i])
      }
    }

    return h(
      'div',
      {
        class: 'tm-message',
      },
      content
    )
  },
})
