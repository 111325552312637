
import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButtonMore from '@/components/shared/TmButtonMore.vue'
import { singleWindow } from '@/services/singleWindow'
import { useTicketsOpenModals } from '@/compositions/tickets/useTicketsOpenModal'
import { notify } from '@kyvg/vue3-notification'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmDropdownItem,
    TmDropdown,
    TmButtonMore,
  },
  props: {
    isNote: {
      type: Boolean,
    },
    modelValue: {
      type: Boolean,
    },
    response: {
      type: String,
    },
  },
  emits: ['edit', 'update:model-value'],
  setup() {
    const { openDeleteNoteModal } = useTicketsOpenModals()
    const { openModal } = useModals()

    const showEmail = () => {
      singleWindow(900, 600, '/view-original-email')
    }

    const handleCopy = () => {
      notify({ text: 'Text successfully copied.', type: 'success' })
    }

    const handleCopyLink = () => {
      notify({ text: 'Direct link successfully copied.', type: 'success' })
    }

    const handleCreateTask = () => {
      openModal('tasks', {
        description: 'Related to the ticket 324354 “Trouble with patient login - urgently need help”\nRelated message:\n”Started investigation on patient login errors reported...”\nRead more',
      })
    }

    return {
      openDeleteNoteModal,
      showEmail,
      openModal,
      handleCopy,
      handleCopyLink,
      handleCreateTask,
    }
  },
})
