
import { defineComponent, ref, computed } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import type { PersonType } from '@/definitions/shared/types'
import type { PropType } from 'vue'
import TmCountdown from '@/components/shared/TmCountdown.vue'
import { useModals } from '@/compositions/modals'
import { editorBtns } from '@/definitions/shared/editor/data'
import TmAttachment from '@/components/shared/TmAttachment.vue'
import Highlight from '@/components/shared/Highlight.vue'
import TmTemplateMessage from '@/components/shared/TmTemplateMessage.vue'
import TicketMessageActions from '@/components/pages/tickets/details/ticketMessage/TicketMessageActions.vue'
import type { TicketEventType } from '@/definitions/tickets/types'
import TmColoredUl from '@/components/shared/TmColoredUl.vue'
import TmTooltipDropdown from '@/components/shared/TmTooltipDropdown.vue'
import { useTicketsOpenModals } from '@/compositions/tickets/useTicketsOpenModal'
import TicketMessageInfo from '@/components/pages/tickets/details/ticketMessage/TicketMessageInfo.vue'

export default defineComponent({
  components: {
    TicketMessageInfo,
    TmTooltipDropdown,
    TicketMessageActions,
    Highlight,
    TmColoredUl,
    TmTemplateMessage,
    TmAttachment,
    TmCountdown,
    TmDropdown,
    TmTooltip,
    TmButton,
    TmAvatar,
  },
  props: {
    collapsed: {
      type: Boolean,
    },
    isAgent: {
      type: Boolean,
    },
    person: {
      type: Object as PropType<PersonType>,
    },
    text: {
      type: String,
      default: '',
    },
    search: {
      type: String,
      default: '',
    },
    showEvents: {
      type: Boolean,
    },
    date: {
      type: String,
    },
    to: {
      type: String,
    },
    isNote: {
      type: Boolean,
    },
    unread: {
      type: Boolean,
    },
    unsend: {
      type: Boolean,
    },
    imageAttachment: {
      type: String,
    },
    attachments: {
      type: Array,
      default: () => [],
    },
    previewState: {
      type: Boolean,
    },
    events: {
      type: Array as PropType<TicketEventType[]>,
      default: () => [],
    },
  },
  emits: ['unsend-message', 'toggle-collapse'],
  setup(props, { slots }) {
    const { openDeleteNoteModal } = useTicketsOpenModals()
    const { openModal } = useModals()
    const localText = ref(props.text)
    const trimmedVisible = ref(false)
    const isEditState = ref(false)
    const infoDropdownState = ref(false)
    const actionsDropdownState = ref(false)
    const editorButtons = [
      editorBtns.text,
      editorBtns.emoji,
      editorBtns.attachment,
      editorBtns.tags,
      editorBtns.image,
      editorBtns.mention,
    ]

    const infoText = computed(() => props.isNote ? 'left internal note' : props.person!.email ? `<${props.person!.email}>` : '')
    const dateText = computed(() => props.date ? props.date : `1 Apr, 2:24 pm ${props.previewState ? '' : '(2 days ago)'}`)
    const showInfoComputed = computed(() => slots.info || props.isNote || (!props.isAgent && !props.previewState))
    const handleTrimmedClick = () => {
      if (!props.isAgent) {
        trimmedVisible.value = true
      }
    }

    return {
      handleTrimmedClick,
      openModal,
      infoDropdownState,
      actionsDropdownState,
      openDeleteNoteModal,
      editorButtons,
      localText,
      isEditState,
      trimmedVisible,
      dateText,
      infoText,
      showInfoComputed,
    }
  },
})
