
import { defineComponent, ref } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'

export default defineComponent({
  components: {
    TmDropdown,
  },
  setup() {
    const buttons = ref([
      {
        title: 'Acknowledge',
        icon: require('@/assets/images/emoji/handshake.png'),
        text: 'the cancellation and thank them for the update',
      },
      {
        title: 'Confirm',
        icon: require('@/assets/images/emoji/thumb-up.png'),
        text: 'that the sender settings are correct and request further assistance if needed',
      },
      {
        title: 'Offer',
        icon: require('@/assets/images/emoji/women-1.png'),
        text: 'to provide any necessary support in the future',
      },
    ])
    const state = ref(false)

    return {
      state,
      buttons,
    }
  },
})
