
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmPriority from '@/components/shared/TmPriority.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmAssignee from '@/components/shared/TmAssignee.vue'
import { formatDate } from '@/services/dateTimeService'
import TmButton from '@/components/shared/TmButton.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import TicketsDropdownActions from '@/components/pages/tickets/TicketsDropdownActions.vue'
import TmCarbonCopy from '@/components/shared/TmCarbonCopy.vue'
import TicketsPreview from '@/components/pages/tickets/TicketsPreview.vue'
import { useTicketsOpenModals } from '@/compositions/tickets/useTicketsOpenModal'
import Highlight from '@/components/shared/Highlight.vue'

export default defineComponent({
  components: {
    Highlight,
    TicketsPreview,
    TmTooltip,
    TmButton,
    TmCarbonCopy,
    TicketsDropdownActions,
    TmEllipsis,
    TmAvatar,
    TmTable,
    TmPriority,
    TmStatus,
    TmAssignee,
  },
  props: {
    search: {
      type: String,
    },
  },
  setup() {
    const { openAssigneeModal } = useTicketsOpenModals()
    const isNewTicket = (line: any) => {
      return line?.row?.statusTicket?.name?.toLowerCase() === 'new'
    }

    return {
      openAssigneeModal,
      isNewTicket,
      formatDate,
    }
  },
})
