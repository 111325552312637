
import { defineComponent } from 'vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  setup() {
    const { openModal, closeModal } = useModals()
    const openAttachModal = (isMMs: boolean) => {
      closeModal('grayTileButtons')
      openModal(
        'attachFile',
        {
          title: isMMs ? 'Attach a file - MMS' : 'Attach a file - Insert download link',
          mmsFormats: isMMs,
          infos: isMMs
            ? [
                'You can send images via MMS that are up to 3.5 MB and other files up to 525 KB in size.',
                'A multimedia message (MMS) will be sent to your recipient\'s phone including the attached file.',
                'Attachments will be stored on Textmagic\'s servers.',
              ]
            : [
                'You can upload files that are up to 10 MB in size.',
                'A clickable hyperlink will be included in your SMS where recipients can view / download your file.',
                'Attachments will be stored on Textmagic\'s servers.',
              ],
        }
      )
    }

    const openAttachFileToMessageModal = () => openModal('grayTileButtons', {
      modalTitle: 'Attach a file',
      items: [
        {
          icon: 'link',
          label: 'Insert download link',
          description: 'A clickable link to the file will be added to your message.',
          onClick: () => openAttachModal(false),
        },
        {
          icon: 'tmi-add-img',
          label: 'Send MMS',
          description: 'A multimedia message will be sent to your recipient’s phone.',
          onClick: () => openAttachModal(true),
        },
      ],
    })

    return {
      openAttachFileToMessageModal,
    }
  },
})
