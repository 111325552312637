
import { defineComponent } from 'vue'
import FilterCheckbox from '@/components/shared/filters/FilterCheckbox.vue'
import TmPriority from '@/components/shared/TmPriority.vue'

export default defineComponent({
  name: 'FilterStatuses',
  components: { TmPriority, FilterCheckbox },
  props: {
    outlineOptions: {
      type: Boolean,
    },
  },
})
