
import { defineComponent } from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TicketMessage from '@/components/pages/tickets/details/TicketMessage.vue'

const agentPerson = {
  uid: '1',
  firstName: 'Dianne',
  lastName: '',
  fullName: 'Dianne',
  avatar: 'https://randomuser.me/api/portraits/women/57.jpg',
  avatarColor: 'red',
}

const userPerson = {
  uid: '2',
  firstName: 'Ralph',
  lastName: 'Edwards',
  fullName: 'Ralph Edwards',
  avatar: '',
  avatarColor: 'red',
}

const items = [
  {
    person: agentPerson,
    text: 'Hello,\n\nUtility based upselling. Sagittis lorem platea nunc, amet tempus donec aliquet nec non. Bibendum nascetur nibh diam sit tincidunt ac sagittis, suscipit in. Est cursus pretium mauris commodo, montes. Proin leo leo diam feugiat et ultricies.\n\nBest regards, Chris.',
    isAgent: true,
    attachments: [
      {
        title: 'Pipeline data 2022',
        extension: 'csv',
        size: '8.2 MB',
      },
    ],
  },
  {
    person: agentPerson,
    text: '@Lucas Joseph, client came up with a nice idea, we need to think about it with Dev team.',
    isNote: true,
    isAgent: true,
  },
  {
    person: userPerson,
    text: 'Yes, please contact our online chat. Risus, amet volutpat risus.',
  },
]

export default defineComponent({
  components: {
    TicketMessage,
    TmStatus,
    TmTooltip,
  },
  setup() {
    return {
      items,
    }
  },
})
