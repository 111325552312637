import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45030fd1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ticket-message-info" }
const _hoisted_2 = { class: "ticket-message-info__label" }
const _hoisted_3 = { class: "ticket-message-info__value" }
const _hoisted_4 = {
  key: 0,
  class: "font-weight-normal distinct--text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.details, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: "ticket-message-info__row"
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(item.label) + ":", 1),
        _createElementVNode("div", _hoisted_3, [
          _createTextVNode(_toDisplayString(item.value) + " ", 1),
          (item.sender)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, " <" + _toDisplayString(item.sender) + "> ", 1))
            : _createCommentVNode("", true)
        ])
      ]))
    }), 128))
  ]))
}