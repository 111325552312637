
import { defineComponent, ref } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import DropdownFilterStatuses from '@/components/shared/filters/dropdown/DropdownFilterStatuses.vue'
import DropdownFilterRadio from '@/components/shared/filters/radio/DropdownFilterRadio.vue'
import DropdownFilterPriority from '@/components/shared/filters/dropdown/DropdownFilterPriority.vue'
import DropdownFilterAssignee from '@/components/shared/filters/dropdown/DropdownFilterAssignee.vue'
import FilterAdd from '@/components/shared/filters/FilterAdd.vue'
import { useModals } from '@/compositions/modals'
import { useTicketsOpenModals } from '@/compositions/tickets/useTicketsOpenModal'
import type { FiltersListType } from '@/definitions/shared/filters/types'
import type { PropType } from 'vue'
import { assigneeField, ticketsStatusField, tagsField } from '@/definitions/_general/_data/bulkFields'

export default defineComponent({
  components: {
    FilterAdd,
    TopFilterButton,
    TopFilter,
    DropdownFilterAssignee,
    DropdownFilterStatuses,
    DropdownFilterRadio,
    DropdownFilterPriority,
  },
  props: {
    selected: {
      type: Array,
    },
    modalActions: {
      type: Boolean,
    },
    filters: {
      type: Object as PropType<FiltersListType>,
    },
  },
  emits: ['update:radio-value', 'update:model-value', 'reset'],
  setup(props) {
    const { openModal } = useModals()
    const { openDeleteTicketsModal, openAssigneeBulkModal, openMultipleMerge, followMessage, openSolveTickets, openMarkAsSpamModal } = useTicketsOpenModals()
    const fieldsList = ref([
      ticketsStatusField,
      assigneeField,
      tagsField,
    ])

    const clickEdit = () => {
      openModal('bulkEdit', {
        fields: fieldsList,
        selected: props.selected,
        itemName: 'ticket',
        addLabel: 'Select contact field',
      })
    }

    return {
      openSolveTickets,
      openMultipleMerge,
      openDeleteTicketsModal,
      clickEdit,
      openAssigneeBulkModal,
      openModal,
      followMessage,
      openMarkAsSpamModal,
    }
  },
})
