
import { defineComponent } from 'vue'
import DropdownFilterCheckbox from '@/components/shared/filters/dropdown/DropdownFilterCheckbox.vue'
import FilterPriority from '@/components/shared/filters/FilterPriority.vue'
import { priorityVariant } from '@/definitions/_general/_data/priorityVariant'

export default defineComponent({
  name: 'DropdownFilterPriority',
  components: { FilterPriority, DropdownFilterCheckbox },
  props: {
    name: {
      type: String,
      default: 'Priority',
    },
  },
  setup() {
    return {
      priorityVariant,
    }
  },
})
