
import { defineComponent } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'

export default defineComponent({
  components: {
    TmDropdown,
    TmDropdownItem,
  },
  setup() {
    const tags = [
      'First name',
      'Last name',
      'Phone',
      'Email',
      'Company name',
      'Test field',
    ]

    return {
      tags,
    }
  },
})
