import { markRaw } from 'vue'
import MessageEditorAttachment from '@/components/shared/chat/messageEditor/buttons/MessageEditorAttachment.vue'
import CannedResponsesDropdown from '@/components/shared/editor/editorDropdowns/CannedResponsesDropdown.vue'
import MessageEditorTags from '@/components/shared/chat/messageEditor/buttons/MessageEditorTags.vue'
import MessageEditorAI from '@/components/shared/chat/messageEditor/buttons/MessageEditorAI.vue'
import type { EditorBtnRecord } from '@/definitions/shared/editor/types'

export const editorBtns: EditorBtnRecord = {
  divider: {
    name: 'divider',
  },
  text: {
    name: 'text',
    iconCustomSize: '26px',
    icon: 'text_format',
    text: 'Formatting',
    emitName: 'update:show-formatting',
    isActive: false,
  },
  emoji: {
    name: 'emoji',
    icon: 'tmi-emoticon-outline',
    text: 'Emoji',
  },
  attachment: {
    name: 'attachment',
    icon: 'tmi-attachment',
    text: 'Attach file',
    component: markRaw(MessageEditorAttachment),
  },
  assignment: {
    name: 'assignment',
    icon: 'flash_on',
    text: 'Canned response',
    component: markRaw(CannedResponsesDropdown),
  },
  tags: {
    name: 'tags',
    icon: 'sell',
    component: markRaw(MessageEditorTags),
    text: 'Add tag',
  },
  code: {
    name: 'code',
    icon: 'code',
    text: 'Code',
  },
  mention: {
    name: 'mention',
    icon: 'alternate_email',
    text: 'Add mention',
  },
  signature: {
    name: 'signature',
    icon: 'tmi-signature',
    text: 'Add signature',
  },
  ai: {
    name: 'ai',
    icon: 'tmi-ai-assistant',
    component: markRaw(MessageEditorAI),
    text: 'Artificial intelligence',
  },
  schedule: {
    name: 'schedule',
    icon: 'event',
    text: 'Schedule message',
    emitName: 'show-schedule',
  },
  preview: {
    name: 'preview',
    icon: 'visibility',
    text: 'Preview message',
  },
  note: {
    name: 'note',
    icon: 'tmi-add-note',
    text: 'Add note',
    emitName: 'toggle-note-state',
  },
  image: {
    name: 'image',
    icon: 'image',
    text: 'Insert image',
  },
  expand: {
    name: 'expand',
    icon: 'tmi-open-fullscreen',
    text: 'Expand editor',
  },
  templates: {
    name: 'templates',
    icon: 'assignment',
    text: 'Insert template',
  },
}

export const cannedResponsesDropdownItems = [
  {
    title: '#goodbye',
    text: 'Thank you convallis dui posuere a enim...',
  },
  {
    title: '#refund',
    text: 'Hello {{first name}}, Thank you for your em...',
    attachments: 2,
  },
  {
    title: '#welcome',
    text: 'Hello and weclome tortor mauris. In etiam... ',
  },
  {
    title: '#thank you',
    text: 'We are appreciate vel blandit purus eu dui...',
  },
  {
    title: '#help',
    text: 'Here are instructio vivamus dui commodo ...',
  },
  {
    title: '#welcome',
    text: 'Hello and weclome tortor mauris. In etiam... ',
  },
  {
    title: '#thank you',
    text: 'We are appreciate vel blandit purus eu dui...',
  },
  {
    title: '#help',
    text: 'Here are instructio vivamus dui commodo ...',
  },
]
