import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_button_more = _resolveComponent("tm-button-more")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, {
    "model-value": _ctx.modelValue,
    tooltip: "More"
  }, {
    menu: _withCtx(() => [
      _renderSlot(_ctx.$slots, "actions", {}, () => [
        (_ctx.isNote)
          ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
              key: 0,
              label: "Edit",
              icon: "edit",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('edit')))
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_tm_dropdown_item, {
          label: "Create task",
          icon: "tmi-layers-tasks",
          onClick: _ctx.handleCreateTask
        }, null, 8, ["onClick"]),
        _createVNode(_component_tm_dropdown_item, {
          label: "Copy text",
          icon: "tmi-duplicate",
          onClick: _ctx.handleCopy
        }, null, 8, ["onClick"]),
        _createVNode(_component_tm_dropdown_item, {
          label: "Share direct link",
          icon: "link",
          onClick: _ctx.handleCopyLink
        }, null, 8, ["onClick"]),
        _createVNode(_component_tm_dropdown_item, {
          label: "Save as canned",
          icon: "tmi-flash-on-plus",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openModal('ticketsCannedResponses', { initResponse: _ctx.response })))
        }),
        (!_ctx.isNote)
          ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
              key: 1,
              label: "Print message",
              icon: "print"
            }))
          : _createCommentVNode("", true),
        (!_ctx.isNote)
          ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
              key: 2,
              label: "Show original",
              class: "gt-md",
              icon: "visibility",
              onClick: _ctx.showEmail
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.isNote)
          ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
              key: 3,
              label: "Delete",
              icon: "delete",
              onClick: _ctx.openDeleteNoteModal
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tm_button_more, {
        horizontal: "",
        "icon-size": "xLarge",
        "btn-size": "small",
        "no-border": "",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('update:model-value', !_ctx.modelValue)), ["stop"]))
      })
    ]),
    _: 3
  }, 8, ["model-value"]))
}