
import { defineComponent } from 'vue'
import { ticketMessageInfo } from '@/definitions/tickets/data'

export default defineComponent({
  setup() {
    const details = ticketMessageInfo

    return { details }
  },
})
